export function ValidateEmail(email){
    //const emailPattern = /^(?!.*\.\.)(?!.*\.$)(?!.*@{2,})(?!.*[^@\w\.-])[\w\.-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/i;
    const emailPattern = /^(?!.*\.\.)(?!.*\.$)(?!.*@{2,})(?!.*[^@\w\.-])[\w\.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+$/i;
    const IsValid = emailPattern.test(email);
    return {
        IsValid : IsValid,
        MessageIfNotValid : 'Formato de correo no válido'
    }      
}


export function ValidatePassword(password){
    const passwordPattern = /^(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=!*])[A-Za-z\d@#$%^&+=!*]{9,}$/;

    const IsSimplePassword = ['abc12345', '12345abc', 'password123'].some(t=>t.toLowerCase()===password.toLowerCase());

    const IsValid = passwordPattern.test(password) && !IsSimplePassword;
    return {
        IsValid : IsValid,
        MessageIfNotValid : 'La contraseña debe contener al menos 8 caracteres, incluyendo letras minúsculas, al menos una mayúscula, al menos un número y al menos uno de los siguientes caracteres especiales: ! @ # $ % & *'
    }      
}