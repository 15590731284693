
import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import axios from 'axios';
import { Col,  Button } from "reactstrap";
import Loader from "react-loaders";

//import config from "../../config.json";
import config from '../config.json'
import logo from "../assets/utils/images/Logo-Proeri.png";
//import background from '../Account/assets/Foto1.jpg'
import bg1 from "../assets/utils/images/originals/PROERI-PORTAL1.jpg";

class ConfirmarAsistencia extends Component {

    constructor(props) {
      super(props);
      this.navigate = this.navigate.bind(this);
      this.state = {
        activationMessage : "",
        loading : true,
        ok: false,
        token:"",
        values:{
          email:"",
          nombreUsuario:"",
          empresa:"",
        }
    };

    this.registrarConfirmacion = this.registrarConfirmacion.bind(this);
}

handleChange = (e) => {
  const { name, value } = e.target;
  this.setState({
    values: {
      ...this.state.values,
      [name]: value,
    },
  });
};

  componentDidMount(){

    const url = process.env.REACT_APP_API + config.API_ESOURCING_OFFER_QUALIFICATION+"/Confirmar";
    
    const params = new URLSearchParams(window.location.search);

        const procId = params.get('procId');
        const token = params.get('token');

        if(token){
            axios.post(url, {
                Token : token 
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then(resp=>{
    
                if(resp.data.operacionConExito){
                    this.setState({activationMessage : "Su cuenta fue activada exitosamente", loading: false, ok: true});
                }else{
                    this.setState({activationMessage : resp.data.error || "Error", loading: false});
                }
    
            }).catch(err=>{
                this.setState({activationMessage : "Error de petición o token inválido", loading: false});
            });
        }

  }

  registrarConfirmacion()
  {
    console.log("test")
    const url = process.env.REACT_APP_API + config.API_ESOURCING_OFFER_QUALIFICATION+"/ActualizarConfirmacion";
    
    const params = new URLSearchParams(window.location.search);

        const procId = params.get('procId');
        const token = params.get('token');

        if(token){
            axios.post(url, {
                ProcurementId : procId,
                Token : token ,
                SupplierName : this.state.values.nombreUsuario,
                CompanyName : this.state.values.empresa,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then(resp=>{
    
                if(resp.data.operacionConExito){
                    this.setState({activationMessage : resp.data.id===1?"Registro actualizado":"Su participación ya estaba registrada", loading: false, ok: resp.data.id===1?false: true});
                }else{
                    this.setState({activationMessage : resp.data.error || "Error", loading: false});
                }
    
            }).catch(err=>{
                this.setState({activationMessage : "Error de petición o token inválido", loading: false});
            });
        }
  }

  navigate(){
    //console.log(this.props);
    //this.props.history.push("/login");
    window.location.href = '/login';
  }

  render (){


    /*
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh;
      background-color: #A7FCA2;
    */

    return <Fragment>
    <div  style={{backgroundImage : `url('${bg1}')`,  
                        backgroundSize: "cover", // La imagen cubrirá el contenedor sin deformarse
                        backgroundPosition: "center", // Centra la imagen en el contenedor
                        backgroundRepeat: "no-repeat", // Evita que la imagen se repita
                        width: "100vw", // Ancho igual al ancho de la ventana
                        height: "100vh",}}
                        >
      <div className="d-flex h-100 justify-content-center align-items-center">
        <Col md="6" className="mx-auto app-login-box">
         
          <div className="modal-dialog w-100">
            <div className="modal-content">
              <div className="modal-header">
                  <div style={{width: '100%',display: 'flex', flexDirection: 'row', justifyContent: 'center', alignContent: 'center'}}>
                    <img src={logo} style={{width: 200, height: 70}}></img>
                  </div>
              </div>
              <div className="modal-body">
        
                     {this.state.loading ?  <div className="loader-wrapper d-flex justify-content-center align-items-center">
                        <Loader type="ball-scale-multiple" />
                      </div> : this.state.ok ? <div>
                          <span style={{textAlign: 'center'}}><b>Confirmación de asistencia!</b></span>
                          <br/>
                          <span style={{textAlign: 'center'}}>
                          Por favor llene la informacion solicitada y confirme su asistencia en la apertura de ofertas.
                          </span>
                          <br/>
                          <div className="mb-2 pt-2">
                                    <label htmlFor="exampleInputEmail1" className="form-label">
                                      {"Contacto (nombre y apellido)"}
                                    </label>
                                    <input
                                              type="text"
                                              className="form-control"
                                              id="nombreUsuario"
                                              aria-describedby="emailHelp"
                                              name="nombreUsuario"
                                              value={this.state.values.nombreUsuario}
                                              ref="nombreUsuario"
                                              onChange={this.handleChange}
                                              />
                          </div> 
                          <div className="mb-2">
                                    <label htmlFor="exampleInputEmail1" className="form-label">
                                      {"Empresa"}
                                    </label>
                                    <input
                                              type="text"
                                              className="form-control"
                                              id="empresa"
                                              aria-describedby="emailHelp"
                                              name="empresa"
                                              value={this.state.values.empresa}
                                              ref="empresa"
                                              onChange={this.handleChange}
                                              />
                          </div> 


                      </div> : <h1 className="h1">{this.state.activationMessage}</h1>}

                    </div>
                        <div className="modal-footer clearfix">
                          <div className="float-right">
                             {this.state.ok &&  <Button className={`btn-icon btn-shadow ml-3`} color="info" size="lg" onClick={this.registrarConfirmacion}>Confirmar</Button> }
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </div>
              </div>
            </Fragment>
  }

};

export default withRouter(ConfirmarAsistencia);