import React, { Component, Fragment } from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
//import { GoogleLogin } from '@react-oauth/google';
import { GoogleLogin } from 'react-google-login';
import { FcGoogle } from 'react-icons/fc';
import axios from "axios";

import { FaEnvelope, FaEyeSlash } from "react-icons/fa";
import { connect } from "react-redux";
import { login } from "../actions/authActions";
import config from '../config.json';
import { withRouter, Redirect } from "react-router-dom";
import { gapi } from "gapi-script";
import Slider from "react-slick";

import bg1 from "../assets/utils/images/originals/PROERI-PORTAL1.jpg";
import bg2 from "../assets/utils/images/originals/PROERI-PORTAL2.jpg";
import logo from "../assets/utils/images/Logo-Proeri.png";

import { Col, Row, Button, Form, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input , InputGroup} from "reactstrap";
import Swal from 'sweetalert';

const mensajeLogin='';
const NEW_SUPPLIER = {
  "CompanyName": null,
  "TaxId": null,
  "Country": null,
  "Name": null,
  "LastName": null,
  "Phone": null,
  "Email": null,
  "Password": null
}

const AUTH_MANUAL = {
  "Email": null,
  "Pass": null
}


class Login extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      newSupplier: NEW_SUPPLIER,
      auth_manual: AUTH_MANUAL,
      loadingRegister: false,
      showPass: false,
      errorMessages : {
        passwordLenght: "",
        notEqualPassword : "",
        emailPattern : "",
        existingEmail: ""
      }
    };

    window.gapi.load("auth2", () => {
      window.gapi.auth2.init({
        client_id: config.GOOGLE_CLIENT_ID,
        scope: "email",
      })
    })

    this.toggle = this.toggle.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleInputChangeLogin = this.handleInputChangeLogin.bind(this); 
    this.handleInputUnfocus = this.handleInputUnfocus.bind(this);
    this.hasErrors = this.hasErrors.bind(this);
    this.onClickRegisterNewSupplier = this.onClickRegisterNewSupplier.bind(this); 
    this.onClickLoginManual = this.onClickLoginManual.bind(this); 
  }

  componentDidMount() {
    console.log("props")
    console.log(this.props)
  }

  onFailure = (error) => {
    alert(error);
  };

  toggle() {
    this.setState({
      showModal: !this.state.showModal,
    });
  }

  googleResponse = (response) => {
    if (!response.tokenId) {
      console.error("Unable to get tokenId from Google", response)
      return;
    }

    const bod = { 'tokenId': response.tokenId }
    const values = JSON.stringify(bod);

    const options = {
      method: 'POST',
      headers: { 'Access-Control-Allow-Origin': true,'Content-Type': 'application/json' },
      body: values,
      /*mode: 'cors',
      cache: 'default'*/
    };

    let url = process.env.REACT_APP_API + config.GOOGLE_AUTH_CALLBACK_URL;
    fetch(url, options)
      .then(r => {
        r.json().then(user => {
          const token = user.token;
          if(user.autenticado ===1)          
          {
            this.props.login(user);
            var data = {id:3,url:""};
            console.log("search: ",this.props.location.search,)
            this.props.history.push({
                pathname: '/',
                //query:data,
                search: this.props.location.search,
              }) 
          }
          else
          {
          this.renderModal(user.error);     
          Swal('Advertencia', user.error, 'warning');       
          }
        });
      })
  };

  /*
  //let url = process.env.REACT_APP_API + config.GOOGLE_AUTH_CALLBACK_URL;
  //console.log(url)

  fetch(url, options)
    .then(r => {
      r.json().then(user => {
        const token = user.token;
        if(user.autenticado ===1)   */       

  renderModal(mensaje) {
    this.mensajeLogin = mensaje;
  }

  //Registro de proveedor
  handleInputChange = (event) => {
    const target = event.target;    

    let newSupplier = this.state.newSupplier;
    newSupplier[target.name] = target.value;
    
    this.setState({
      newSupplier: newSupplier
    }); 
  }

  handleInputUnfocus = (event)=>{
       const {name, value} = event.target;    

       let errorMessage = "";
       let errorMessageName = "";


       if(name === "Password"){
         errorMessageName = "passwordLenght";
         if((!value || value.length < 8)){
           errorMessage = "La contraseña debe tener al menos 8 caracteres";
         }else{
          errorMessage = "";
         }
       }


        if(name === "Password_Confirm"){
          errorMessageName = "notEqualPassword";
          if(this.state.newSupplier.Password !== value){
            errorMessage = "Contraseñas no coinciden";
          }else{
            errorMessage = "";
          }
      }

      
      
      if(name === "Email"){
        
        errorMessageName = "emailPattern";
        const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

          if(!validRegex.test(value)){
            errorMessage = "Formato de Email incorrecto";
          }else{
            errorMessage = "";
          }
      }

       

       this.setState((prevState)=>({
          ...prevState,
          errorMessages : {
            ...prevState.errorMessages,
            [errorMessageName] : errorMessage
          }
       })
      )
  }

  hasErrors(){
    return Object.values(this.state.errorMessages).some(err=>err !== "");
  }


  //Login
  handleInputChangeLogin = (event) => {
    const target = event.target;    

    let auth_manual = this.state.auth_manual;
    auth_manual[target.name] = target.value;
    
    this.setState({
      auth_manual: auth_manual
    }); 
  }

  onClickRegisterNewSupplier(){

    this.setState({
      loadingRegister: true
    })
   
    if(this.hasErrors()){
      this.setState({
        loadingRegister: false
      })
      return;
    }

    let url = process.env.REACT_APP_API + config.API_AUTH_MANUAL;
    axios.put(url , this.state.newSupplier)
      .then((response) => {  
        console.log(response);

        if(response.data.operacionConExito){
          Swal('Usuario Registrado', 'Se ha enviado un correo para verificación', 'success');
          this.setState({showModal : false, newSupplier: NEW_SUPPLIER });
        }else{
          this.setState((prevState)=>({
            ...prevState,
            errorMessages : {
              ...prevState.errorMessages,
              emailPattern : response.data.error
            },
            loadingRegister: false
            }) 
          );
        }



      }).catch((error) => {
        console.log(error);
        //this.setState({showModal : false});
      })      
  }

  onClickLoginManual(){     
    let data = {Email:this.state.auth_manual.Email, Password:this.state.auth_manual.Pass};

    let url = process.env.REACT_APP_API + config.GOOGLE_AUTH_CALLBACK_URL+"/LoginWithPassword";
    axios.post(url, data)
      .then((response) => {  
        //console.log(response);
        if(response.data.autenticado === 1)
        {
          this.props.login(response.data);          
          this.props.history.push({
              pathname: '/',              
              search: this.props.location.search,
            }) 
        }
        else
        {
          //this.renderModal(response.data.error);            
          Swal('Advertencia', response.data.error, 'warning');
        }
      }).catch((error) => {
        console.log(error);
      }); 
  }





  render() {
    let settings = {
      dots: true,
      infinite: true,
      speed: 500,
      arrows: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
      initialSlide: 0,
      autoplay: true,
      adaptiveHeight: true,
    };
    
    let content = !!this.props.auth.isAuthenticated ?
      (
        <div>
          <Redirect to={{
            pathname: '/'
          }} />
        </div>
      ) :
      (
        <Fragment>
        <div className="h-100">
          <Row className="h-100 g-0">
          <Col lg="4" md="12" sm="12" className="h-100 d-flex bg-white align-items-center">
              <Col xl="9" lg="9" md="7" sm="8" className="app-login-box mx-auto">
                <div >
                  <div className='text-center mb-2' style={{ width: '325px', margin:'auto'}}>
                    <img src={logo} alt="react logo" style={{ width: '325px', }}/>
                  </div>   
                  <div className='text-center mt-4' style={{ width: '325px', margin:'auto'}}>
                    <h4 className='text-danger'><b>AVISO DE USO EXCLUSIVO</b></h4>
                  </div>                                  
                  <div className='text-center mt-4' style={{ width: '360px', margin:'auto'}}>
                  <span>
                  Este sistema es de uso exclusivo para el personal autorizado de {" "}
                    <span className="text-primary"><b>UNOPS</b></span> y para los organismos ejecutores del proyecto PROERI (
                      <span className="text-primary"><b>CNE, CONAVI, INCOFER, MEP, MIVAH</b></span>
                      ). El acceso no autorizado está prohibido y puede ser sujeto a acciones legales. Si tienes alguna pregunta o necesitas asistencia, por favor contacta al soporte técnico.
                    </span>
                  </div>                                  
                  <div style={{ width: '270px', margin:'auto', paddingTop:'40px', textAlign:'center'}}>
                    <Row>
                      <Col md="12" className="mb-4">
                        <GoogleLogin
                            //color="primary"
                            scope= 'profile email'
                            clientId={config.GOOGLE_CLIENT_ID}
                            buttonText="Inicia sesión con Google"
                            onSuccess={this.googleResponse}
                            onFailure={this.googleResponse}
                            theme= 'dark'
                            width= '240'
                            height= '50'
                            longtitle= 'true'
                            cookiePolicy={"single_host_origin"}
                            //scope="profile"            
                          />
                      </Col>
                    </Row>                          
                    {/*}<div className="divider"></div> {*/}
                    <Row className="text-center">
                      <Col md={12} className="mt-4" >
                        <span>------- ó Ingrese correo y contraseña -------</span>
                      </Col>
                      <Col md="12" className="mt-4">                        
                      <div class="input-group pb-2">
                          <input type="email" name="Email" id="Email" class="form-control" placeholder="Correo electrónico" onChange={this.handleInputChangeLogin}/>
                          <span class="">
                              <span class="input-group-text bg-white">
                              <i className="btn-icon-wrapper"><FaEnvelope color="primary" size={16} /> </i>
                              </span>
                          </span>
                      </div>                      
                      {/*}<div class="input-group">
                          <input type="password" name="Pass" id="Pass" class="form-control" placeholder="Contraseña" onChange={this.handleInputChangeLogin}/>
                          <span class="">
                              <span class="input-group-text bg-white">
                              <i className="btn-icon-wrapper"><FaEyeSlash color="primary" size={16} /> </i>
                              </span>
                          </span>
                      </div> {*/}
                        <InputGroup>
                              <Input type={!this.state.showPass ? 'password' : 'text'} name="Pass" id="Pass" placeholder="Ingrese contraseña" 
                              onChange={this.handleInputChangeLogin}  /* onBlur={this.handlePaswordChange}  *//>
                                <Button color={!this.state.showPass ? 'info' : 'danger'} onClick={()=>this.setState({showPass: !this.state.showPass})}>
                                    {!this.state.showPass ?  <i className='pe-7s-look'></i> : <i className='pe-7s-lock'></i>}
                                </Button>
                            </InputGroup>  
                        <FormGroup>  
                            {/*}<Input type="email" name="Email" id="Email" placeholder="Correo electrónico" onChange={this.handleInputChangeLogin} />{*/}
                        </FormGroup>
                        <FormGroup>
                            {/*}<Input type="password" name="Pass" id="Pass" placeholder="Contraseña" onChange={this.handleInputChangeLogin}/>{*/}
                        </FormGroup>
                        <FormGroup>
                          <Button style={{width : "100%"}} className="btn btn-success" onClick={this.onClickLoginManual} >Iniciar sesión</Button>
                        </FormGroup>                        

                      </Col>                      
                    </Row>
                    {/*}<Row>
                      <Col md="6">
                        <Button style={{width : "100%"}} color="link">Olvidé mi contraseña</Button>
                      </Col>
                      <Col md="6">
                        <Button style={{width : "100%"}} color="link" onClick={this.toggle} >Registrarse</Button>
                      </Col>
                    </Row>{*/}
                  
                  {/*}<GoogleOAuthProvider 
                      clientId={config.GOOGLE_CLIENT_ID}
        >{*/}
                   
                  {/*}</GoogleOAuthProvider>{*/}
                  
                  </div>
                </div>
              </Col>
            </Col>

            <Col lg="8" className="d-none d-lg-block">
              <div className="slider-light">
                <Slider {...settings}>
                  <div className="h-100 d-flex justify-content-center align-items-center bg-premium-dark">
                    <div className="slide-img-bg"
                      style={{
                        backgroundImage: "url(" + bg1 + ")",
                      }}/>
                  </div>
                  <div className="h-100 d-flex justify-content-center align-items-center bg-premium-dark">
                    <div className="slide-img-bg opacity-6"
                      style={{
                        backgroundImage: "url(" + bg2 + ")",
                      }}/>
                  </div>
                </Slider>
              </div>
            </Col>

          </Row>
        </div>
        {/* <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
                    <ModalHeader toggle={this.toggle}>Login</ModalHeader>
                    <ModalBody>
                      <p>{ this.mensajeLogin}</p>
                    </ModalBody>
                    <ModalFooter>
                    <Button color="primary" onClick={this.toggle}>
                    Aceptar
                    </Button>
                    </ModalFooter>
                </Modal>  */}

        <Modal isOpen={this.state.showModal} toggle={this.toggle} size="lg" >
          <ModalHeader >Datos del proveedor</ModalHeader>
          <ModalBody>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label for="CompanyName">Nombre de la entidad</Label>
                  <Input type="text" name="CompanyName" id="CompanyName" placeholder="Entidad" onChange={this.handleInputChange} />
                </FormGroup>  
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label for="TaxId">Registro único tributario o número comercial</Label>
                  <Input type="text" name="TaxId" id="TaxId" placeholder="Registro tributario / número comercial" onChange={this.handleInputChange} />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label for="Country">Pais</Label>
                  <Input type="text" name="Country" id="Country" placeholder="Pais" onChange={this.handleInputChange} /> 
                </FormGroup>
              </Col>
              <Col md="6">
                
              </Col>  
            </Row>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label for="Name">Nombre</Label>
                  <Input type="text" name="Name" id="naNameme" placeholder="Ingrese su nombre" onChange={this.handleInputChange}/>
                </FormGroup>
              </Col>
              <Col md="6">  
                <FormGroup>
                  <Label for="LastName">Apellidos</Label>
                  <Input type="text" name="LastName" id="LastName" placeholder="Ingrese su apellido" onChange={this.handleInputChange} />
                </FormGroup>
              </Col>
            </Row>
            
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label for="Phone">Teléfono</Label>
                  <Input type="text" name="Phone" id="Phone" placeholder="Teléfono" onChange={this.handleInputChange} />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label for="Email">Correo</Label>
                  <Input type="email" name="Email" id="Email" placeholder="Ingrese su correo" onChange={this.handleInputChange} onBlur={this.handleInputUnfocus}/>
                  {this.state.errorMessages.emailPattern !== "" && <span style={{color: 'red'}}>{this.state.errorMessages.emailPattern}</span>}
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md="6">
                <FormGroup>
                  <Label for="Password">Contraseña</Label>
                  <Input type="password" name="Password" id="Password" placeholder="Ingrese su contraseña" onChange={this.handleInputChange} onBlur={this.handleInputUnfocus}/>
                  {this.state.errorMessages.passwordLenght !== "" && <span style={{color: 'red'}}>{this.state.errorMessages.passwordLenght}</span>}
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label for="Password_Confirm">Confirme su contraseña</Label>
                  <Input type="password" name="Password_Confirm" id="Password_Confirm" placeholder="Confirme su contraseña" onChange={this.handleInputChange} onBlur={this.handleInputUnfocus} />
                  {this.state.errorMessages.notEqualPassword !== "" && <span style={{color: 'red'}}>{this.state.errorMessages.notEqualPassword}</span>}
                </FormGroup>
              </Col>
            </Row>

          </ModalBody>
          <ModalFooter>
            <Button color="primary" disabled={this.state.loadingRegister} onClick={this.onClickRegisterNewSupplier} >
              Registrarse
            </Button>{' '}
            <Button color="secondary" onClick={this.toggle} >
              Cancelar
            </Button>
          </ModalFooter>
        </Modal>
      </Fragment>
      );

    return (
      <div>
          {content}
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (token) => {
      dispatch(login(token));
    }
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
